<template>
  <div class="c-app">
    <TheSidebar ref="sidebar" />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <b-container
            fluid
            :class="[
              $route.name === 'Report Customer Detail'
                ? 'report_customer_detail'
                : '',
            ]"
          >
            <transition name="fade">
              <router-view />

              <!-- </router-view> -->
            </transition>
          </b-container>
        </main>
      </div>
      <!-- <TheFooter/> -->
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },

  methods: {},
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.report_customer_detail {
  padding-right: 15px;
}
</style>
