export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        id: 1,
        _name: "CSidebarNavDropdown",
        name: "Banner",
        key: "banner",
        is_sub_menu: 0,
        icon: "cil-image-1",
        _children: [
          {
            id: 2,
            _name: "CSidebarNavItem",
            name: "Banner",
            key: "banner",
            to: "/banner",
            is_sub_menu: 1,
            key_valid: "sub_banner",
          },
          {
            id: 3,
            _name: "CSidebarNavItem",
            name: "Product Banner",
            key: "product_banner",
            to: "/productbanner",
            is_sub_menu: 1,
            key_valid: "sub_product_banner",
          },
        ],
      },
      {
        id: 4,
        _name: "CSidebarNavDropdown",
        name: "Product Mobile",
        key: "product_mobile",
        is_sub_menu: 0,
        icon: "cil-list",
        _children: [
          {
            id: 5,
            _name: "CSidebarNavItem",
            name: "Category",
            key: "category",
            is_sub_menu: 1,
            to: "/category",
            key_valid: "sub_category",
          },
          {
            id: 6,
            _name: "CSidebarNavItem",
            name: "Product Mobile",
            key: "product_mobile",
            is_sub_menu: 1,
            to: "/productmobile",
            key_valid: "sub_product_mobile",
          },
          {
            id: 7,
            _name: "CSidebarNavItem",
            name: "Sales Channel",
            key: "sales_channel",
            key_valid: "sub_sales_channel",
            is_sub_menu: 1,
            to: "/saleschannel",
          },
        ],
      },
      {
        id: 8,
        _name: "CSidebarNavItem",
        name: "Video",
        to: "/video",
        icon: "cil-video",
        key: "video",
        is_sub_menu: 0,
        // _children: [
        //   {
        //     id: 9,
        //     _name: "CSidebarNavItem",
        //     name: "Video",
        //     to: "/video",
        //     key: "video",
        //     key_valid: "sub_video",
        //     is_sub_menu: 1,
        //   },
        // ],
      },
      {
        id: 10,
        _name: "CSidebarNavDropdown",
        name: "Privilege",
        icon: "cil-gift",
        key: "privilege",
        is_sub_menu: 0,
        _children: [
          {
            id: 11,
            _name: "CSidebarNavItem",
            name: "Privilege Code",
            to: "/privilegecode",
            key: "privilege_code",
            key_valid: "sub_privilege_code",
            is_sub_menu: 1,
          },
          {
            id: 12,
            _name: "CSidebarNavItem",
            name: "Beauty Passport",
            to: "/stampcard",
            key: "beauty_passport",
            key_valid: "sub_beauty_passport",
            is_sub_menu: 1,
          },
          {
            id: 13,
            _name: "CSidebarNavItem",
            name: "Coupon",
            to: "/coupon",
            key: "coupon",
            key_valid: "sub_coupon",
            is_sub_menu: 1,
          },
        ],
      },
      {
        id: 14,
        _name: "CSidebarNavItem",
        name: "News/Promotion News",
        to: "/promotionnews",
        icon: "cil-newspaper",
        key: "news_promotion_news",
        is_sub_menu: 0,
      },
      {
        id: 15,
        _name: "CSidebarNavItem",
        name: "Notification",
        to: "/notification",
        icon: "cil-bell",
        key: "notification",
        is_sub_menu: 0,
      },
      {
        id: 16,
        _name: "CSidebarNavItem",
        name: "Campaign Forms",
        to: "/form",
        icon: "cil-columns",
        key: "campaign_forms",
        is_sub_menu: 0,
      },
      {
        id: 17,
        _name: "CSidebarNavDropdown",
        name: "Marketing Automation",
        icon: "cil-loop-circular",
        key: "marketing_automation",
        is_sub_menu: 0,
        _children: [
          {
            id: 18,
            _name: "CSidebarNavItem",
            name: "Automation",
            to: "/automation",
            key: "automation",
            key_valid: "sub_automation",
            is_sub_menu: 1,
          },
          {
            id: 19,
            _name: "CSidebarNavItem",
            name: "Send Message",
            to: "/automation/sendmessage",
            key: "send_message",
            key_valid: "sub_send_message",
            is_sub_menu: 1,
          },
        ],
      },
      // {
      //   id: 20,
      //   _name: "CSidebarNavDropdown",
      //   name: "Sale Data",
      //   icon: "cil-storage",
      //   key: "sale_data",
      //   is_sub_menu: 0,
      //   _children: [
      //     {
      //       id: 21,
      //       _name: "CSidebarNavItem",
      //       name: "Customer",
      //       to: "/customer",
      //       key_valid: "sub_customer",
      //       is_sub_menu: 1,
      //     },
      //     {
      //       id: 22,
      //       _name: "CSidebarNavItem",
      //       name: "Transaction",
      //       to: "/transaction",
      //       key_valid: "sub_transaction",
      //       is_sub_menu: 1,
      //     },
      //   ],
      // },
      {
        id: 23,
        _name: "CSidebarNavItem",
        name: "Member Tier",
        to: "/membertier",
        icon: "cil-layers",
        key: "member_tier",
        is_sub_menu: 0,
      },
      {
        id: 24,
        _name: "CSidebarNavItem",
        name: "Redeem",
        to: "/redeem",
        icon: "cil-puzzle",
        key: "redeem",
        is_sub_menu: 0,
      },
      {
        id: 25,
        _name: "CSidebarNavItem",
        name: "Customer",
        to: "/report/customer",
        icon: "cil-people",
        key: "customer",
        is_sub_menu: 0,
      },
      {
        id: 26,
        _name: "CSidebarNavItem",
        name: "Product",
        to: "/product",
        icon: "cil-list",
        key: "product",
        is_sub_menu: 0,
      },
      {
        id: 27,
        _name: "CSidebarNavItem",
        name: "Transaction",
        to: "/report/transaction",
        icon: "cil-credit-card",
        key: "transaction",
        is_sub_menu: 0,
      },
      {
        id: 28,
        _name: "CSidebarNavItem",
        name: "Extra Point",
        to: "/extrapoint",
        icon: "cil-layers",
        key: "extra_point",
        is_sub_menu: 0,
      },
      {
        id: 29,
        _name: "CSidebarNavItem",
        name: "Right Request",
        to: "/allrightrequest",
        icon: "cil-layers",
        key: "all_right_request",
        is_sub_menu: 0,
      },

      {
        id: 30,
        _name: "CSidebarNavItem",
        name: "Activity Log",
        to: "/activitylog",
        icon: "cil-list",
        key: "activity_log",
        is_sub_menu: 0,
      },

      {
        id: 32,
        _name: "CSidebarNavDropdown",
        name: "Permission",
        icon: "cil-lock-locked",
        key: "permission",
        is_sub_menu: 0,
        _children: [
          {
            id: 33,
            _name: "CSidebarNavItem",
            name: "Admin",
            to: "/managePermission",
            key: "permission Admin",
            key_valid: "sub_permission_admin",
            is_sub_menu: 1,
          },
          {
            id: 34,
            _name: "CSidebarNavItem",
            name: "Role",
            to: "/manageRole",
            key: "permission Role",
            key_valid: "sub_permission_role",
            is_sub_menu: 1,
          },
        ],
      },
      {
        id: 35,
        _name: "CSidebarNavItem",
        name: "Staff Management",
        to: "/manageStaff",
        icon: "cil-people",
        key: "manageStaff",
        is_sub_menu: 0,
      },

      {
        id: 36,
        _name: "CSidebarNavItem",
        name: "Branch Management",
        to: "/manageBranch",
        icon: "cil-home",
        key: "manageBranch",
        is_sub_menu: 0,
      },
      {
        id: 31,
        _name: "CSidebarNavItem",
        name: "Setting",
        to: "/setting",
        icon: "cil-settings",
        key: "setting",
        is_sub_menu: 0,
      },
    ],
  },
];
