<template>
  <CSidebar fixed :show.sync="show" id="sidebar">
    <CSidebarBrand
      :imgFull="{
        width: 170,
        alt: 'Logo',
        src: logoImage,
        class: 'logo-container',
      }"
    />

    <CRenderFunction flat :content-to-render="nav" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  components: {},

  computed: {
    logoImage() {
      return this.$store.state.theme.Logo;
    },
  },
  data() {
    return {
      minimize: false,
      nav,
      show: "responsive",
      theme: {},
      ishavePermission: [],
      routeMeta: {},
      imageLogo: "",
    };
  },
  created() {
    this.handleResize();
    setTimeout(async () => {
      this.handleActiveMenuChild(this.$route.meta);
    }, 650);
  },
  watch: {
    "$route.meta"(newValue, oldValue) {
      this.handleActiveMenu(newValue, oldValue);
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth > 991)
      window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted: async function () {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
      if (!this.show) {
        document.body.style.setProperty("--margin-spacing", "0");
      } else {
        document.body.style.setProperty("--margin-spacing", "256px");
      }
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
    this.getPermissionList();
    const ele = document.getElementsByClassName(
      "c-sidebar-nav-dropdown-toggle"
    );

    // Array.from(ele).forEach((element) => {
    //   element.addEventListener("click", (e) => {
    //     let ele = element.parentElement;
    //     // ele.classList.remove("c-show");
    //     // e.stopPropagation();

    //     ele.className = "c-sidebar-nav-dropdown";
    //     if (ele.className == "c-sidebar-nav-dropdown c-show") {
    //       ele.className = "c-sidebar-nav-dropdown";
    //     } else {
    //       ele.className = "c-sidebar-nav-dropdown c-show";
    //     }
    //     e.stopPropagation();
    //     // e.target.click();
    //   });
    // });
  },

  methods: {
    handleResize() {
      const screenSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (window.innerWidth <= 991) {
        document.body.style.setProperty("--margin-spacing", "0");
      } else {
        document.body.style.setProperty("--margin-spacing", "256px");
      }
      this.$store.commit("setScreenSize", screenSize);
    },
    handleActiveMenu: async function (newValue, oldValue) {
      document.body.style.setProperty("--margin-bottom", "0px");
      if (newValue.activeUrl != oldValue.activeUrl) {
        await this.handleRemoveActiveMenuChild(oldValue, newValue);
      }
      await this.handleActiveMenuChild(newValue);
    },
    handleActiveMenuChild: async function (meta) {
      this.routeMeta = meta;
      var ele = await this.getAllElementsWithAttribute(
        "a",
        "href",
        meta.activeUrl
      );
      if (meta.isChildren && ele.className == "c-sidebar-nav-link")
        await ele.setAttribute("class", "active c-sidebar-nav-link");

      var parentElement = ele;
      if (meta.lv == 1 && ele.className == "c-sidebar-nav-dropdown") {
        ele.setAttribute("class", "c-sidebar-nav-dropdown c-show");
      } else if (meta.lv == 2) {
        parentElement = parentElement.parentElement.parentElement.parentElement;
        if (
          parentElement &&
          parentElement.className == "c-sidebar-nav-dropdown"
        )
          parentElement.setAttribute("class", "c-sidebar-nav-dropdown c-show");
      }

      if (
        meta.lv == 3 &&
        parentElement.parentElement.parentElement.className.includes(
          "c-sidebar-nav-dropdown"
        )
      ) {
        if (
          !parentElement.parentElement.parentElement.className.includes(
            "c-show"
          )
        ) {
          parentElement.parentElement.parentElement.setAttribute(
            "class",
            "c-sidebar-nav-dropdown-items"
          );
        }
        ele.classList.add("active");
      }
    },
    handleRemoveActiveMenuChild: async function (oldMeta, newMeta) {
      var oldEle = this.getAllElementsWithAttribute(
        "a",
        "href",
        oldMeta.activeUrl
      );
      if (!oldEle) return;

      oldEle = await this.getAllElementsWithAttribute(
        "a",
        "href",
        oldMeta.activeUrl
      );
      oldEle.classList.remove("active");
      if (oldMeta.parent != newMeta.parent) {
        let dropdown = oldEle.closest(".c-sidebar-nav-dropdown");
        if (dropdown) {
          dropdown.classList.remove("c-show");
        }
      }
    },
    getAllElementsWithAttribute(tagName, attribute, val) {
      var allElements = Array.from(document.getElementsByTagName(tagName));
      var filtered = allElements.filter((ele) => {
        return (
          ele.getAttribute(attribute) !== null &&
          ele.getAttribute(attribute) == val
        );
      });
      if (filtered.length) return filtered[0];
      else return false;
    },
    getPermissionList: async function () {
      let data = await this.$Axios(
        `${process.env.VUE_APP_API}/setting/getpermission`
      );
      if (data.data.result == 1) {
        this.nav[0]._children = this.nav[0]._children.filter((x) => {
          if (x._children) {
            x._children = x._children.filter((el) =>
              data.data.detail.includes(el.id)
            );
          }
          return data.data.detail.includes(x.id);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.c-show {
  .c-sidebar-nav-dropdown-toggle {
    background: var(--primary-color) !important;
    color: var(--font-primary-color) !important;
  }
}
.logo-container {
  max-height: 50px;
  max-width: 170px;
}
</style>
