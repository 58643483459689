<template>
  <CFooter>
    <div>
      <span class="ml-1">CoreUI &copy; 2019 creativeLabs.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
